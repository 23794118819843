import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import {
  FaChevronRight,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa";
import Logo from "../images/LogoBlack.svg";

const Footer = () => {
  return (
    <footer className="bg-gray-300 border-t border-gray-300">
      <div className="container px-5 py-10 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-no-wrap flex-wrap flex-col md:px-12 lg:px-12 xl:px-5">
        <div className="flex-shrink-0 md:mx-0 mx-auto text-center md:w-56 lg:w-80 md:text-left">
          <Link to="/">
            <img
              src={Logo}
              alt="Ostrom Contracting Logo"
              className="max-w-xxs mx-auto md:mx-0 md:max-w-xxxs lg:max-w-xxs xl:max-w-xxs"
            />
          </Link>
          {/* <p className="mt-2 text-sm text-gray-900">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos recusandae corporis eveniet possimus delectus! Odit?</p> */}
          <p className="mt-2 text-sm text-gray-900">
            Your Northeast Wisconsin partner for residential and commercial
            excavation projects and agricultural drainage tiling work.
          </p>
        </div>
        <div className="flex-grow flex flex-wrap md:pl-8 -mb-10 md:mt-0 mt-10 md:text-left md:w-2/3 text-center lg:pl-24 xl:pl-48">
          <div className="md:w-3/5 lg:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3 uppercase">
              Contact Information
            </h2>
            <nav className="list-none mb-10">
              <ul>
                <li>
                  <p className="block my-2">
                    <FaPhoneAlt className="inline-block text-base mr-3 align-middle" />
                    <a
                      href="tel:+19203620108"
                      className="inline-block align-middle text-gray-700 transition duration-500 ease-in-out hover:text-gray-900"
                    >
                      (920) 362-0108
                    </a>
                  </p>
                </li>
                <li>
                  <p className="block my-2">
                    <FaEnvelope className="inline-block text-base mr-3 align-middle" />
                    <a
                      href="mailto:ostrom.mac@gmail.com"
                      className="inline-block align-middle text-gray-700 transition duration-500 ease-in-out hover:text-gray-900"
                    >
                      ostrom.mac@gmail.com
                    </a>
                  </p>
                </li>
                <li>
                  <p className="block my-2">
                    <FaMapMarkerAlt className="inline-block text-base mr-3 align-middle" />
                    <a
                      href="https://www.google.com/maps/place/Freedom,+WI+54130/@44.4277676,-88.3481182,11z/data=!4m5!3m4!1s0x8803ad2b34e38c3b:0x1ddfce742b428a3a!8m2!3d44.3865588!4d-88.2884368"
                      className="inline-block align-middle text-gray-700 transition duration-500 ease-in-out hover:text-gray-900"
                    >
                      Freedom Wisconsin
                    </a>
                  </p>
                </li>
              </ul>
            </nav>
          </div>
          <div className="md:w-2/5 lg:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3 uppercase">
              Useful Links
            </h2>
            <nav className="list-none mb-10">
              <ul>
                <li>
                  <p className="block my-2">
                    <FaChevronRight className="inline-block text-base mr-3 align-middle" />
                    <Link
                      to="/services"
                      className="inline-block align-middle text-gray-700 transition duration-500 ease-in-out hover:text-gray-900"
                    >
                      Services
                    </Link>
                  </p>
                </li>
                <li>
                  <p className="block my-2">
                    <FaChevronRight className="inline-block text-base mr-3 align-middle" />
                    <Link
                      to="/projects"
                      className="inline-block align-middle text-gray-700 transition duration-500 ease-in-out hover:text-gray-900"
                    >
                      Projects
                    </Link>
                  </p>
                </li>
                <li>
                  <p className="block my-2">
                    <FaChevronRight className="inline-block text-base mr-3 align-middle" />
                    <Link
                      to="/contact"
                      className="inline-block align-middle text-gray-700 transition duration-500 ease-in-out hover:text-gray-900"
                    >
                      Contact
                    </Link>
                  </p>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-gray-300">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-700 text-xs text-center sm:text-left md:text-sm">
            &copy; {new Date().getFullYear()} Ostrom Contracting LLC, All Rights
            Reserved -{" "}
            <a className="underline" href="/privacy-policy">
              Privacy Policy
            </a>
          </p>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
            <p className="text-gray-700 text-xs text-center pr-2 sm:text-left md:text-sm">
              Follow Us on Social Media
            </p>
            <OutboundLink
              href="https://www.facebook.com/Ostrom-Contracting-LLC-410109003053527"
              rel="noreferrer"
              target="_blank"
              className="text-gray-700 transition duration-500 ease-in-out hover:text-gray-900"
            >
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
                alt="Facebook"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </OutboundLink>
            {/* <OutboundLink href="https://twitter.com" rel="noreferrer" target="_blank" className="ml-3 text-gray-700 transition duration-500 ease-in-out hover:text-gray-900">
                  <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24" alt="Twitter">
                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                  </svg>
                </OutboundLink> */}
            {/* <OutboundLink
              href="https://instagram.com"
              rel="noreferrer"
              target="_blank"
              className="ml-3 text-gray-700 transition duration-500 ease-in-out hover:text-gray-900"
            >
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
                alt="Instagram"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </OutboundLink> */}
            {/* <OutboundLink
              href="https://linkedin.com"
              rel="noreferrer"
              target="_blank"
              className="ml-3 text-gray-700 transition duration-500 ease-in-out hover:text-gray-900"
            >
              <svg
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
                className="w-5 h-5"
                viewBox="0 0 24 24"
                alt="Linkedin"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </OutboundLink> */}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
