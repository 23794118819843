import React, { useState } from "react";
import { Link } from "gatsby";
import Logo from "../images/LogoWhite.svg";
import { FaPhoneAlt } from "react-icons/fa";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="bg-gray-900 w-full sticky top-0 px-1 z-20 md:px-6 lg:px-8 xl:px-4">
      <div className="flex flex-wrap items-center justify-between max-w-9xl p-4 mx-auto">
        <Link to="/">
          <img
            src={Logo}
            alt="Ostrom Contracting Logo"
            className="max-w-xxs md:max-w-xs lg:max-w-sm xl:max-w-xs"
          />
        </Link>

        <button
          className="items-center block px-3 py-2 text-white rounded xl:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-5 h-5 fill-current md:w-8 md:h-8 lg:w-10 lg:h-10"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } xl:block xl:items-center w-full xl:w-auto`}
        >
          {[
            {
              route: `/services`,
              title: `Services`,
            },
            {
              route: `/projects`,
              title: `Projects`,
            },
            {
              route: `/contact`,
              title: `Contact`,
            },
          ].map((link) => (
            <Link
              className="block mt-4 text-white text-lg uppercase font-bold no-underline text-center transition duration-500 ease-in-out hover:text-ocyellow py-1 xl:inline-block md:mt-0 md:ml-6 md:text-xl lg:text-2xl"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
          <p className="block mt-4 text-white text-lg font-medium no-underline text-center transition duration-500 ease-in-out xl:inline-block md:mt-0 md:ml-6 md:text-2xl hover:text-gray-500">
            <FaPhoneAlt style={{ display: "inline-block" }} />{" "}
            <a href="tel:+19203620108"> (920) 362-0108</a>
          </p>
        </nav>
      </div>
    </header>
  );
}

export default Header;
